<template>
  <div>
    <app-image
      v-if="variant === 'transparent'"
      v-bind="logoBinding"
      src="/images/logo_full_white.svg"
    />

    <app-image v-else v-bind="logoBinding" src="/images/logo_full_color.svg" />
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  variant?: "transparent";
}>();

const logoBinding = {
  alt: "Pollen logo",
  class: "w-[125px] desktop-nav:w-[170px]",
  width: 170,
};
</script>
